.categories .MuiTab-root {
  font-size: 14px !important;
  text-transform: unset !important;
  min-width: unset !important;
  font-weight: bold;
  color: #000;
}


.categories .MuiTabs-indicator {
  height: 1.5px !important;
  z-index: 99;
}

.categories .MuiTabs-scrollButtons {
  width: 17px !important;
}

.categories .MuiTabs-scrollButtons .MuiSvgIcon-root {
  font-size: 1.5rem !important;
}
