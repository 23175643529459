.OutletUnavailableDialog .MuiButton-contained {
  border-radius: 50px;
}

.OutletUnavailableDialog .MuiButton-text {
  border-radius: 50px;
  background-color: #dfdddd;
  color: #404040;
}

.OutletUnavailableDialog .MuiPaper-root {
  border-radius: 20px 20px 0 0;
}
