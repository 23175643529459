.snack-bar .MuiAlert-message {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.snack-bar .MuiAlert-action {
  display: flex;
  align-items: center !important;
}

.snack-bar .MuiPaper-root {
  min-height: 49px;
  border-radius: 10px !important;
}

.snack-bar .MuiAlert-filledSuccess {
  background-color: #139036a3 !important;
}
.snack-bar .MuiAlert-filledError {
  background-color: #ae1208a3 !important;
}

.snack-bar .MuiAlert-icon {
  display: none;
}
