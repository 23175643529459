#dropin-container .adyen-checkout__card__brands {
  display: none;
}

/* .adyen-checkout__payment-method__details {
  padding: 0;
} */

/* .adyen-checkout__payment-method__header {
  display: none;
} */
.adyen-checkout__payment-method {
  background-color: transparent;
}

.adyen-checkout__button {
  margin-bottom: 15px;
  background-color: #000;
}
/* ul
    li:last-child
    .adyen-checkout__payment-method__details__content:last-of-type {
    padding-top: 16px;
    border-top: 1px solid #7c7c7c;
  } */

.adyen-checkout__label__text {
  font-size: 14px;
  padding-bottom: 12px;
}
