.item-detail .item-detail-wrapper {
  padding-bottom: 140px;
}

.item-detail .MuiButton-contained {
  border-radius: 100px;
  padding: 14.5px 16px;
  font-size: 16px;
}

.item-detail .MuiButton-simplePrimary {
  padding: 14.5px 16px;
  font-size: 16px;
}

.item-detail .MuiButtonGroup-root {
  border-radius: 100px;
  border: 1px solid #000;
  overflow: hidden;
  background-color: #fff;
}
