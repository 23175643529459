.fixed-outlet-detail {
  position: fixed;
  width: 100vw !important;
  background-color: #fff;
}

.fixed-category {
  position: fixed;
  width: 100vw;
  top: 92px;
}
