html,
head,
body,
.landscape,
.portrait,
#wrapper,
.MuiContainer-root,
#app {
  height: 100%;
}

#menu-container{
  scroll-padding-top:64px;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

body {
  overflow-x: hidden;
}
.MuiDialog-container .MuiPaper-root {
  overflow-x: hidden;
}

.App {
  text-align: center;
}

#App {
  overflow-x: hidden;
  overscroll-behavior-x: none;
  -webkit-touch-callout: none;
}

* {
  overscroll-behavior-x: none;
  -webkit-touch-callout: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
input::-webkit-date-and-time-value {
  text-align: left;
}

input[type="email"]::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: left !important;
}
.lessPadding .MuiInputBase-root input::placeholder {
  text-align: "left !important";
  padding: "0px !important";
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.landscape {
  display: none;
}
.portrait {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  background-color: #fff;
  overflow-x: hidden;
}

@media screen and (orientation: landscape) {
  .landscape {
    display: block;
  }
  /* .portrait {
    display: none;
  } */

  /**/
}
